import axios from "axios";

axios.defaults.baseURL = "https://new-test.houseplus.it";
// "https://new-prod.houseplus.it";
// "http://houseplus-core-new-main.eba-jh6apbpp.eu-south-1.elasticbeanstalk.com/";
// "https://houseplus-new-algo-test-3dbb52e7ae2f.herokuapp.com"; sd

export const setAuthHeaders = () =>
  (axios.defaults.headers.common = {
    "x-access-token": `${localStorage.getItem("jwtokenpuppeteer")}`,
  });

export const removeAuthHeader = () =>
  delete axios.defaults.headers.common["x-access-token"];

export class Api {
  static getInstance() {
    return new Api();
  }

  get(url, options) {
    setAuthHeaders();
    return axios.get(`${url}`, {
      withCredentials: true,
      credentials: "include",
      ...options,
    });
  }

  put(url, data, options) {
    setAuthHeaders();
    return axios.put(`${url}`, data, {
      withCredentials: true,
      credentials: "include",
      ...options,
    });
  }

  post(url, data, options) {
    options && options.isPublic ? removeAuthHeader() : setAuthHeaders();
    return axios.post(`${url}`, data, {
      withCredentials: true,
      credentials: "include",
      ...options,
    });
  }

  patch(url, data, options) {
    setAuthHeaders();
    return axios.patch(`${url}`, data, {
      withCredentials: true,
      credentials: "include",
      ...options,
    });
  }

  delete(url, options) {
    setAuthHeaders();
    return axios.delete(`${url}`, {
      withCredentials: true,
      credentials: "include",
      ...options,
    });
  }
}
