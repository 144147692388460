import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import styles from "./Houseplus.module.scss";
import { actions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../Auth/store/selectors";

const PaymentsAndCredits = ({ userPortal }) => {
  const { subscriptionType, subscriptionExpiry, isWhiteLabel } = useSelector(
    getUser()
  );
  const dispatch = useDispatch();
  const isExpired =
    subscriptionExpiry && new Date(subscriptionExpiry) < new Date();

  return (
    <>
      <div
        className={styles.subscription}
        style={{
          gridColumn: (!subscriptionType || isExpired) && "1/7",
        }}
      >
        <div>
          <h3>
            {subscriptionType == "monthly" && !isExpired
              ? `Il tuo abbonamento PREMIUM ${
                  isWhiteLabel ? "WHITELABEL" : ""
                } mensile è attivo`
              : subscriptionType == "yearly" && !isExpired
              ? `Il tuo abbonamento PREMIUM ${
                  isWhiteLabel ? "WHITELABEL" : ""
                } annuale è attivo`
              : "Scegli il pacchetto PREMIUM che preferisci"}
          </h3>
          {subscriptionType && !isExpired ? (
            <p style={{ marginTop: 20 }}>
              Con il tuo abbonamento puoi usufruire illimitatamente del pieno
              potenziale di Houseplus
            </p>
          ) : null}
        </div>
        {(!subscriptionType || isExpired) && (
          <div className={styles.packages}>
            <div className={styles.type}>
              <div
                className={styles.package}
                style={{ backgroundColor: "white", color: "#034349" }}
              >
                <div className={styles.title}>
                  <h3>MENSILE</h3>
                  <p>
                    Scopri il meglio con il nostro Piano Premium Mensile!
                    Accesso illimitato a tutte le funzionalità avanzate e report
                    illimitati. Abbonati ora e inizia subito a godere dei
                    vantaggi!
                  </p>
                </div>
                <div className={styles.cta}>
                  <p className={styles.product__price}>€ 59 + IVA / mese</p>
                  <div>
                    <PrimaryButton
                      padding="10px"
                      width="250px"
                      text="Acquista"
                      fontSize="20px"
                      onClick={() => {
                        dispatch(
                          actions.GET_PAYMENT_URL_PACKAGE.REQUEST(
                            {
                              idPrice: "price_1PIRRxLUjZBu6YVsZSeh1bWO",
                              type: "monthly",
                            },
                            (url) => window.open(url)
                          )
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={styles.package}
                style={{ backgroundColor: "#11606d", color: "#fff7fa" }}
              >
                <div className={styles.title}>
                  <h3>ANNUALE</h3>
                  <p>
                    Risparmia con il nostro Piano Premium Annuale! Ottieni un
                    anno intero di accesso illimitato a tutte le funzionalità
                    avanzate e report illimitati per soli €590 + iva. Abbonati
                    oggi e goditi un anno di vantaggi premium!
                  </p>
                </div>
                <div className={styles.cta}>
                  <p className={styles.product__price}>€ 590 + iva / anno</p>
                  <div>
                    <PrimaryButton
                      padding="10px"
                      width="250px"
                      text="Acquista"
                      background={"white"}
                      color={"black"}
                      fontSize="20px"
                      onClick={() => {
                        dispatch(
                          actions.GET_PAYMENT_URL_PACKAGE.REQUEST(
                            {
                              idPrice: "price_1PIRSTLUjZBu6YVsEfJpX6b5",
                              type: "yearly",
                            },
                            (url) => window.open(url)
                          )
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.type}>
              <div
                className={styles.package}
                style={{ backgroundColor: "#5e239d", color: "#fff7fa" }}
              >
                <div className={styles.title}>
                  <h3>MENSILE WHITELABEL</h3>
                  <p>
                    Potenzia il tuo brand con il nostro Piano White Label
                    Mensile! Personalizza l'interfaccia con il logo e la palette
                    colori della tua agenzia. Abbonati ora e offri ai tuoi
                    clienti un'esperienza su misura!
                  </p>
                </div>
                <div className={styles.cta}>
                  <p className={styles.product__price}>€ 149 + IVA / mese</p>
                  <div>
                    <PrimaryButton
                      padding="10px"
                      width="250px"
                      text="Acquista"
                      fontSize="20px"
                      background={"white"}
                      color={"black"}
                      onClick={() => {
                        dispatch(
                          actions.GET_PAYMENT_URL_PACKAGE.REQUEST(
                            {
                              idPrice: "price_1PIRTeLUjZBu6YVsyy3uvWyv",
                              type: "monthlywhite",
                            },
                            (url) => window.open(url)
                          )
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={styles.package}
                style={{ backgroundColor: "#FFD700", color: "#fff7fa" }}
              >
                <div className={styles.title}>
                  <h3>ANNUALE WHITELABEL</h3>
                  <p>
                    Massimizza il risparmio con il nostro Piano White Label
                    Annuale! Goditi un anno intero di personalizzazione completa
                    con il logo e la palette colori della tua agenzia per soli
                    €1490 + iva. Abbonati oggi!
                  </p>
                </div>
                <div className={styles.cta}>
                  <p className={styles.product__price}>€ 1490 + IVA / anno</p>
                  <div>
                    <PrimaryButton
                      padding="10px"
                      width="250px"
                      text="Acquista"
                      fontSize="20px"
                      background={"white"}
                      color={"black"}
                      onClick={() => {
                        dispatch(
                          actions.GET_PAYMENT_URL_PACKAGE.REQUEST(
                            {
                              idPrice: "price_1PIRT8LUjZBu6YVsAPVZqvWx",
                              type: "yearlywhite",
                            },
                            (url) => window.open(url)
                          )
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {subscriptionType && !isExpired && (
          <PrimaryButton
            padding="15px 10px"
            width="50%"
            height="auto"
            text="Gestisci abbonamento"
            background="white"
            color="black"
            fontSize="20px"
            onClick={() => window.open(userPortal, "_blank")}
          />
        )}
      </div>
      {/* CREDITS */}
      {subscriptionType && !isExpired && (
        <div className={styles.credits}>
          <span>
            Il tuo abbonamento PREMIUM {isWhiteLabel ? "WHITELABEL" : ""}{" "}
            {subscriptionType == "monthly"
              ? "mensile"
              : subscriptionType == "yearly"
              ? "annuale"
              : null}{" "}
            e' attivo fino al:
          </span>
          <p>{subscriptionExpiry?.split("T")[0]}</p>
          {/* <CreditIcon style={{ marginRight: 10 }} />
        <div>
          <p>{userCredits}</p>
          <span>Report disponibili</span>
        </div> */}
        </div>
      )}
    </>
  );
};

export default PaymentsAndCredits;

// PLUS B2C REPORT PROD price_1PIR4iLUjZBu6YVsnDoSDeSa
// Abbonamento mensile B2B PREMIUM PROD price_1PIRMRLUjZBu6YVskTivBeQw
// Abbonamento annuale B2B PREMIUM PROD price_1PIRNYLUjZBu6YVsep3kDn8G
// Abbonamento mensile B2B PREMIUM - Whitelabel PROD price_1PIROqLUjZBu6YVsmAfzy6ea
// Abbonamento annuale B2B PREMIUM - Whitelabel PROD price_1PIROFLUjZBu6YVsDQMZpNLg

// PLUS B2C REPORT TEST price_1PIR9vLUjZBu6YVsf4f90TTb
// Abbonamento mensile B2B PREMIUM TEST price_1PIRRxLUjZBu6YVsZSeh1bWO
// Abbonamento annuale B2B PREMIUM TEST price_1PIRSTLUjZBu6YVsEfJpX6b5
// Abbonamento mensile B2B PREMIUM - Whitelabel TEST price_1PIRTeLUjZBu6YVsyy3uvWyv
// Abbonamento annuale B2B PREMIUM - Whitelabel TEST price_1PIRT8LUjZBu6YVsAPVZqvWx
